import React from 'react'

export default function Copyright() {
    return (
        <div className='flex justify-center text-gray-400 text-xs my-10'>
            <div className='text-center'>
                <p>MirrorLink</p>
                <p>Developed Copyright @DevelopingKerala</p>
            </div>

        </div>
    )
}
